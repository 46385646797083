import GLightbox from 'glightbox';

export const lightboxInit = () => {
  const customLightboxHTML = `<div id="glightbox-body" class="glightbox-container">
    <div class="gloader visible"></div>
    <div class="goverlay"></div>
    <div class="gcontainer">
    <button class="gprev gbtn gbtn2" tabindex="1" aria-label="Previous"><svg><use xlink:href="#arrow-left"></use></svg></button>
    <div id="glightbox-slider" class="gslider"></div>
    <button class="gnext gbtn gbtn2" tabindex="0" aria-label="Next" data-customattribute="example"><svg><use xlink:href="#arrow-right"></use></svg></button>
    <div class="nav-wrap"></div>
    <button class="gclose gbtn" tabindex="2" aria-label="Close"><svg><use xlink:href="#close"></use></svg></button>
</div>
</div>`;

  /* 
  <div class="nav-wrap">
    <button class="gprev gbtn" tabindex="1" aria-label="Previous"><svg><use xlink:href="#arrow-left"></use></svg></button>
    <button class="gnext gbtn" tabindex="0" aria-label="Next" data-customattribute="example"><svg><use xlink:href="#arrow-right"></use></svg></button>
    </div> */

  const productLightbox = GLightbox({
    selector: '.glightbox',
    zoomable: false,
    skin: 'lab',
    loop: true,
    lightboxHTML: customLightboxHTML,
    closeOnOutsideClick: true,
  });
};
