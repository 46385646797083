import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade } from 'swiper/modules';

export const index3Slider = () => {
  const index3Slider = document.querySelector('.index-3-slider');
  const index3SliderWrapper = index3Slider?.querySelector(
    '.index-3-slider__wrapper'
  );

  if (index3SliderWrapper) {
    const swiper = new Swiper(index3SliderWrapper, {
      slidesPerView: 1,
      // allowTouchMove: true,
      loop: true,
      navigation: {
        nextEl: '.index-3-slider__nav-control--right',
        prevEl: '.index-3-slider__nav-control--left',
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      on: {
        slideChange: (swiper) => {
          const slideCurrent = index3Slider.querySelector(
            '.nav__current-index'
          );
          const slideOverall = index3Slider.querySelector('.nav__overall');

          slideCurrent.innerHTML = swiper.realIndex + 1;
          slideOverall.innerHTML = swiper.slides.length; // - swiper.loopedSlides * 2;
        },
      },
      modules: [Navigation, Pagination, EffectFade],
    });
  }
};
