export class SearchBar {
  constructor() {
    this.searchBar = document.querySelector('.search');
    this.openSearch = document.querySelector('.header__search-button');
    this.closeSearch = document.querySelector('.search__close');
    this.searchInput = document.querySelector('.search__input');
    this.searchButton = document.querySelector('.search__button');

    if (
      this.searchBar &&
      this.openSearch &&
      this.closeSearch &&
      this.searchInput &&
      this.searchButton &&
      this.searchInput
    ) {
      this.init();
    }
  }

  init = () => {
    this.openSearch?.addEventListener('click', this.open);
    this.closeSearch?.addEventListener('click', this.close);
    this.searchButton?.addEventListener('click', this.searchButtonClick);
  };

  open = () => {
    this.searchBar?.classList.add('active');
    setTimeout(() => {
      this.searchInput?.focus();
    }, 100);

    document.addEventListener('click', this.handleOutsideClick);
  };
  close = () => {
    this.searchInput?.blur();
    this.searchBar?.classList.remove('active');

    document.removeEventListener('click', this.handleOutsideClick);
  };
  searchButtonClick = () => {
    this.searchInput?.blur();
  };

  isOpen = () => {
    return this.searchBar?.classList.contains('active');
  };

  handleOutsideClick = (e) => {
    if (
      e.target.closest('.search') === null &&
      e.target.closest('.header__search-button') === null
    ) {
      this.close();
    }
  };
}
