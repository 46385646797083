import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';
import { vars } from '../vars';

export class ThingsSlider {
  constructor(_options) {
    this.selector = _options.selector;
    this.thingsSliderEl = document.querySelector(this.selector);
    this.thingsSliderWrapper = this.thingsSliderEl?.querySelector(
      `${this.selector}__wrapper`
    );

    this.thingsEl = document.querySelector(_options.thingsSelector);
    this.things = this.thingsEl?.querySelectorAll('.thing__item');
    this.swiper;

    this.DELAY = 5000;
    this.DOCUMENT_STYLE = document.documentElement.style;
    this.DOCUMENT_STYLE.setProperty('--delay', `${this.DELAY / 1000}s`); // set CSS transition delay to match slider delay

    this.interval = null;
    this.progress = 0;
    this.progressStyleProp = `--progress${_options.elSuffix}`;

    this.prefix = `index${_options.elSuffix}`;

    this.SPACE_BETWEEN = {
      index: {
        390: 24,
        744: 32,
        1024: 32,
        1512: 0,
      },
      'index-5': {
        390: 19,
        744: 26,
        1024: 32,
        1512: 0,
      },
    };

    if (this.thingsSliderEl && this.thingsEl) {
      this.init();
    }
  }

  setProgress = (progress) => {
    this.progress = progress || 0;
    this.DOCUMENT_STYLE.setProperty(this.progressStyleProp, `${this.progress}`);
  };

  updateNav = (swiper) => {
    const slideCurrent = this.thingsSliderEl.querySelector(
      '.nav__current-index'
    );
    const slideOverall = this.thingsSliderEl.querySelector('.nav__overall');

    slideCurrent.innerHTML = swiper.realIndex + 1;
    slideOverall.innerHTML = swiper.slides.length; // - swiper.loopedSlides * 2;
  };

  getEffect = () => {
    if (window.screen.availWidth < vars.breakpointDesktop) {
      return 'slide';
    } else return 'fade';
  };

  handleThingClick = () => {
    this.things.forEach((item, index) => {
      item.addEventListener('click', () => {
        this.swiper.slideTo(index);
      });
    });
  };

  init = () => {
    if (this.thingsSliderWrapper) {
      this.swiper = new Swiper(this.thingsSliderWrapper, {
        slidesPerView: 1,
        autoplay: {
          delay: this.DELAY,
        },
        speed: 500,
        effect: this.getEffect(),
        rewind: true,
        navigation: {
          nextEl: `${this.selector}__nav-control--right`,
          prevEl: `${this.selector}__nav-control--left`,
        },
        breakpoints: {
          390: {
            slidesPerView: 'auto',
            effect: 'slide',
            spaceBetween: this.SPACE_BETWEEN[this.prefix]['390'],
          },
          744: {
            slidesPerView: 'auto',
            effect: 'slide',
            spaceBetween: this.SPACE_BETWEEN[this.prefix]['744'],
          },
          1024: {
            slidesPerView: 'auto',
            effect: 'slide',
            spaceBetween: this.SPACE_BETWEEN[this.prefix]['1024'],
          },
          1512: {
            slidesPerView: 1,
            effect: 'fade',
            fadeEffect: {
              crossFade: true,
            },
          },
        },
        on: {
          beforeInit: (swiper) => {
            this.things[0].classList.add('active');
          },
          afterInit: (swiper) => {
            this.updateNav(swiper);
            this.setProgress(100);
            console.log(swiper);
          },
          beforeTransitionStart: (swiper) => {
            setTimeout(() => {
              this.setProgress(0);
            }, 50);
          },

          slideChange: (swiper) => {
            this.updateNav(swiper);

            clearInterval(this.interval);

            this.things.forEach((item) => {
              item.classList.remove('active');
            });

            this.things[swiper.realIndex].classList.add('active');
          },
          beforeSlideChangeStart: (swiper) => {
            this.setProgress(100);

            const index = swiper.realIndex;
            this.things[index].classList.add('change');
            setTimeout(() => {
              this.things[index].classList.remove('change');
            }, 500);
          },
          slideChangeTransitionEnd: (swiper) => {
            this.setProgress(100);
          },
        },

        modules: [Navigation, Pagination, EffectFade, Autoplay],
      });

      this.handleThingClick();
    }
  };
}
