import { Offcanvas } from './Offcanvas';

export default class Header {
  constructor() {
    this.init();
  }

  init = () => {
    this.BREAKPOINT = 744;

    this.header = document.querySelector('.header');
    this.openClass = 'header--open';
    this.menuContainer = document.querySelector('.header-dropdown');
    this.burgerButton = this.header?.querySelector('.burger');

    if (this.menuContainer) {
      this.burgerButton?.addEventListener('click', this.toggleDropdown); // listen to burger button click
      document.addEventListener('click', this.outsideClickHandler);
    }

    this.offcanvas = new Offcanvas();

    window.addEventListener('resize', this.handleResize);
  };

  isOpened = () => {
    // return this.header?.classList.contains(this.openClass);
    return document.body.classList.contains(this.openClass);
  };
  close = () => {
    // this.header?.classList.remove(this.openClass);
    document.body.classList.remove(this.openClass);
  };
  open = () => {
    // this.header?.classList.add(this.openClass);
    if (this.getPageWidth() >= 744) {
      document.body.classList.add(this.openClass);
    } else {
      this.offcanvas?.open();
    }
  };
  getPageWidth = () => {
    return Math.min(window.screen.availWidth, window.innerWidth);
  };
  toggleDropdown = () => {
    this.isOpened() ? this.close() : this.open();
  };

  outsideClickHandler = (e) => {
    // close header menu when clicking outside
    if (!this.header?.contains(e.target) && this.isOpened()) this.close();
  };

  handleResize = () => {
    if (this.getPageWidth() >= this.BREAKPOINT && this.offcanvas?.isOpened()) {
      this.offcanvas?.close();
    } else if (this.getPageWidth() < this.BREAKPOINT && this.isOpened()) {
      this.close();
    }
  };

  destroy = () => {
    this.burgerButton?.removeEventListener('click', this.toggleDropdown);
    document.removeEventListener('click', this.outsideClickHandler);

    window.removeEventListener('resize', this.handleResize);
  };
}
