export class Offcanvas {
  constructor() {
    this.CLS_OPENED = 'opened';
    this.CLS_LEVEL1 = 'active-level-1';
    this.CLS_LEVEL2 = 'active-level-2';
    this.CLS_BODY = 'offcanvas-opened';

    this.init();
  }

  init = () => {
    this.offcanvas = document.querySelector('.offcanvas');
    this.backButton = document.querySelector('.offcanvas__back');
    this.closeButton = document.querySelector('.offcanvas__close');

    this.menuItems = this.offcanvas?.querySelectorAll(
      '.offcanvas__menu-item.parent'
    );
    this.subItems = this.offcanvas?.querySelectorAll(
      '.offcanvas__menu-sub-item.parent'
    );

    this.menuItems?.forEach((item) => {
      const itemLink = item.querySelector('.offcanvas__menu-item-title');
      itemLink?.addEventListener('click', this.handleItemClick);
    });

    this.subItems?.forEach((subItem) => {
      const subItemLink = subItem.querySelector(
        '.offcanvas__menu-sub-item-title'
      );
      subItemLink?.addEventListener('click', this.handleSubItemClick);
    });

    this.backButton?.addEventListener('click', this.handleBackClick);
    this.closeButton?.addEventListener('click', this.close);
  };

  handleItemClick = (e) => {
    console.log('item');

    e.preventDefault();

    e.target?.parentElement.classList.add(this.CLS_LEVEL1);
    this.offcanvas?.classList.add(this.CLS_LEVEL1);
  };

  handleSubItemClick = (e) => {
    e.preventDefault();
    e.target.parentNode.classList.add(this.CLS_LEVEL2);

    e.target.parentNode
      .closest('.offcanvas__menu-item')
      ?.classList.add(this.CLS_LEVEL2);
    this.offcanvas?.classList.add(this.CLS_LEVEL2);
  };

  handleBackClick = () => {
    const menuItemsArray = Array.from(this.menuItems);

    // is last menu level opened
    const isLast = menuItemsArray.find((item) => {
      if (item.classList.contains(this.CLS_LEVEL2)) {
        item.classList.remove(this.CLS_LEVEL2);
        this.offcanvas?.classList.remove(this.CLS_LEVEL2);

        this.subItems?.forEach((subItem) => {
          subItem.classList.remove(this.CLS_LEVEL2);
        });
        return true;
      }
    });

    if (!isLast) {
      menuItemsArray.find((item) => {
        if (item.classList.contains(this.CLS_LEVEL1)) {
          item.classList.remove(this.CLS_LEVEL1);
          this.offcanvas?.classList.remove(this.CLS_LEVEL1);

          return true;
        }
      });
    }
  };

  close = () => {
    document.documentElement.classList.remove(this.CLS_BODY);

    this.menuItems?.forEach((item) => {
      item.classList.remove(this.CLS_LEVEL1);
      item.classList.remove(this.CLS_LEVEL2);
    });

    this.subItems?.forEach((subItem) => {
      subItem.classList.remove(this.CLS_LEVEL2);
    });
  };
  open = () => {
    document.documentElement.classList.add(this.CLS_BODY);
  };

  isOpened = () => {
    return document.documentElement.classList.contains(this.CLS_BODY);
  };

  destroy = () => {
    this.menuItems?.forEach((item) => {
      const itemLink = item.querySelector('.offcanvas__menu-item-title');
      itemLink?.removeEventListener('click', this.handleItemClick);
    });

    this.subItems?.forEach((subItem) => {
      const subItemLink = subItem.querySelector(
        '.offcanvas__menu-sub-item-title'
      );
      subItemLink?.removeEventListener('click', this.handleSubItemClick);
    });
    // close
    // remove all event listeners
  };
}
