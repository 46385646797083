import ymaps from 'ymaps';

import { vars } from './vars';

export default class Map {
  map;
  // breakpoint = 1024;

  constructor(_options) {
    this.mapEl = document.querySelectorAll(_options.id);
    this.placemarkSelector = _options.placemarks;

    if (this.mapEl.length > 0) this.init();
  }

  init = () => {
    // update map on resize - to change center of the map
    // window.addEventListener('resize', this.responsive);

    this.initMap();
  };

  initMap = () => {
    this.mapEl.forEach((currentMap) => {
      ymaps
        .load(
          'https://api-maps.yandex.ru/2.1/?apikey=7831c6db-8a7f-49d5-a7b7-c567b1e05675&lang=ru_RU'
        )
        .then((maps) => {
          this.map = new maps.Map(currentMap, {
            center: this.getMapCenter(currentMap),
            zoom: 15,
            controls: [],
          });
          // this.map.controls.add('zoomControl', {
          //   left: 5,
          //   top: 60,
          // });
          this.map.behaviors.disable('scrollZoom');

          this.loadPlacemarks(maps, currentMap);
        })
        .catch((error) => console.log('Failed to load Yandex Maps', error));
    });
  };

  loadPlacemarks = (maps, currentMap) => {
    const coordinates = this.parseCoordinates(currentMap, 'coordinates');

    let myPlacemark = new maps.Placemark(
      coordinates,
      {
        // hintContent: '',
        balloonContent: currentMap.dataset.address,
      },
      {
        iconLayout: 'default#image',
        iconImageHref: vars.mapPlacemarkImage,
        iconImageSize: [81, 74],
        iconImageOffset: [-40, -74],
      }
    );
    this.map.geoObjects.add(myPlacemark);
  };

  parseCoordinates(element, attribute) {
    return JSON.parse(decodeURIComponent(element.dataset[attribute]));
  }

  destroy = () => {
    this.map?.destroy();
    // window.removeEventListener('resize', this.responsive);
  };
  update = () => {
    this.destroy();
    this.init();
  };

  getMapCenter(currentMap) {
    // const coordinates = this.parseCoordinates(
    //   this.activePanel.querySelector('.contacts__contacts'),
    //   'center'
    // );
    // const coordinatesSmall = this.parseCoordinates(
    //   this.activePanel.querySelector('.contacts__contacts'),
    //   'centerSmall'
    // );
    return this.parseCoordinates(currentMap, 'coordinates');

    // return window.screen.availWidth >= this.breakpoint
    //   ? coordinates
    //   : coordinatesSmall;
  }
}
