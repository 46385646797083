export default class FormTextareaResize {
  // JavaScript to handle auto-resizing
  constructor(textareaSelector) {
    this.textareaEl = document.querySelectorAll(textareaSelector);

    this.textareaEl.forEach((textarea) => {
      textarea.addEventListener('input', this.autoResize);
    });
  }

  autoResize = (e) => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };
}
