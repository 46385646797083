export const handleFooterMenu = () => {
  const footerMenus = document.querySelectorAll('.footer__menu');
  footerMenus.forEach((menu) => {
    const menuTitle = menu.querySelector('.footer__menu-title');
    const menuContent = menu.querySelector('.footer__menu-list');

    if (menuTitle && menuContent) {
      menuTitle?.addEventListener('click', function () {
        menu.classList.toggle('collapsed');
        const isCollapsed = menu.classList.contains('collapsed');
        menuTitle.setAttribute('aria-expanded', isCollapsed ? 'false' : 'true');
        menuContent.setAttribute('aria-hidden', isCollapsed ? 'true' : 'false');

        menuContent.style.maxHeight = isCollapsed
          ? null
          : menuContent.scrollHeight + 'px';
      });
    }
  });
};
