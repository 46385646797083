import Choices from 'choices.js';

export const choicesFormInit = () => {
  const selects = document.querySelectorAll('.js-choice');

  if (selects.length > 0) {
    selects.forEach((select) => {
      const options = select.querySelectorAll('option');

      const choices = new Choices(select, {
        searchEnabled: false,
        itemSelectText: '',
        noResultsText: '',
        noChoicesText: '',
        shouldSort: false,
        callbackOnInit: null,
        classNames: {
          containerOuter: 'choices choices--form',
        },
      });
    });
  }
};
