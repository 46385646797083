import DOMPurify from 'dompurify';
import IMask from 'imask';
export const formPhoneMask = () => {
  document.querySelectorAll('.form__phone').forEach((item) => {
    const maskOptions = {
      mask: '+{7}(000)000-00-00',
      lazy: false,
    };
    const mask = IMask(item, maskOptions);
  });
};

export const formProductTitle = () => {
  const messageInputs = document.querySelectorAll('.form__textarea');
  const productTitle = document.querySelector('.product__title');

  if (productTitle) {
    const productTitleEsc = DOMPurify.sanitize(productTitle.innerText);
    messageInputs.forEach((textarea) => {
      textarea.innerText = productTitleEsc;
    });
  }
};
