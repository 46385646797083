export const productTabsInit = () => {
  const tabs = document.querySelectorAll('.product__tab-link');
  const tabContents = document.querySelectorAll('.product__tab-item');
  const SCROLL_OFFSET = -100;
  const productTabsEl = document.querySelector('.product__tabs');
  const propsTabLink = document.querySelector('.product__props-link');

  tabs.forEach((tab, index) => {
    tab.addEventListener('click', (e) => {
      e.preventDefault();
      const target = tabContents[index];
      tabContents.forEach((content) => {
        content.classList.remove('active');
      });
      tabs.forEach((tab) => {
        tab.classList.remove('active');
      });
      tab.classList.add('active');
      target.classList.add('active');
    });
  });

  if (propsTabLink) {
    const propsPropName = propsTabLink.dataset.tabName;

    propsTabLink.addEventListener('click', (e) => {
      e.preventDefault();

      const productTabsY =
        productTabsEl?.getBoundingClientRect().y +
        window.scrollY +
        SCROLL_OFFSET;

      tabContents.forEach((content) => {
        if (content.dataset.tabName === propsPropName) {
          content.classList.add('active');
          window.scrollTo({ top: productTabsY, behavior: 'smooth' });
        } else {
          content.classList.remove('active');
        }
      });
      tabs.forEach((tab) => {
        if (tab.dataset.tabName === propsPropName) {
          tab.classList.add('active');
        } else {
          tab.classList.remove('active');
        }
      });
    });
  }
};
