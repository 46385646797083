// JavaScript for the file drag and drop functionality

export class FileDragDrop {
  constructor(dropAreaEl) {
    this.dropArea = document.querySelector(dropAreaEl);
    this.fileInput = this.dropArea?.querySelector('input[type="file"]');
    this.dropAreaText = this.dropArea?.querySelector('span');

    if (this.dropArea && this.fileInput) {
      this.init();
    }
  }

  init() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      this.dropArea.addEventListener(eventName, this.preventDefaults, false);
    });

    ['dragenter', 'dragover'].forEach((eventName) => {
      this.dropArea.addEventListener(eventName, this.highlight, false);
    });

    ['dragleave', 'drop'].forEach((eventName) => {
      this.dropArea.addEventListener(eventName, this.unhighlight, false);
    });

    this.dropArea.addEventListener('drop', this.handleDrop, false);
  }

  preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  highlight = () => {
    this.dropArea.classList.add('highlight');
  };

  unhighlight = () => {
    this.dropArea.classList.remove('highlight');
  };

  handleDrop = (e) => {
    // let dt = e.dataTransfer;
    let files = e.dataTransfer.files;

    files = [...files];
    let file = files[0];

    // Create a DataTransfer object and add the files to it
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);

    // Assign the DataTransfer's FileList to the input element
    this.fileInput.files = dataTransfer.files;

    if (this.dropAreaText) {
      this.dropAreaText.textContent = `${file.name}`;
    }
  };
}
