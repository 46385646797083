export const setSectionsHeight = () => {
  const activeSectionSub = document.querySelector(
    '.index-catalog__section.active .index-catalog__section-subs-wrap'
  );

  if (activeSectionSub) {
    activeSectionSub.style.maxHeight = `${activeSectionSub.scrollHeight}px`;
  }
  // set container min height to prevent height jumping while hovering
  const sectionsBlock = document.querySelector('.index-catalog__right');
  let isSectionsBlockHeightSet = false;

  activeSectionSub?.addEventListener('transitionend', () => {
    if (!isSectionsBlockHeightSet) {
      sectionsBlock.style.minHeight = `${sectionsBlock.scrollHeight}px`;
      isSectionsBlockHeightSet = true;
    }
  });

  document.querySelectorAll('.index-catalog__section *').forEach((el) => {
    el.addEventListener('mouseover', (e) => {
      e.stopPropagation();
    });
  });

  const sections = document.querySelectorAll('.index-catalog__section');
  sections.forEach((section) => {
    ['mouseover'].forEach((event) => {
      section.addEventListener(event, (e) => {
        setTimeout(() => {
          e.preventDefault();
          const subs = section.querySelector(
            '.index-catalog__section-subs-wrap'
          );

          sections.forEach((sectOther) => {
            sectOther.classList.remove('active');
            const sectOtherSubs = sectOther.querySelector(
              '.index-catalog__section-subs-wrap'
            );
            if (sectOtherSubs) {
              sectOtherSubs.style.maxHeight = null;
            }
          });

          section.classList.add('active');
          if (subs) {
            subs.style.maxHeight = `${subs.scrollHeight}px`;
          }
        }, 200);
      });
    });
  });
};
