import Choices from 'choices.js';
import Isotope from 'isotope-layout';

export const catalogFilter = () => {
  const selects = document.querySelectorAll('.js-choice--catalog');

  if (selects.length > 0) {
    selects.forEach((select) => {
      select.value = '';

      const options = select.querySelectorAll('option');

      const choices = new Choices(select, {
        searchEnabled: false,
        itemSelectText: '',
        noResultsText: '',
        noChoicesText: '',
        shouldSort: false,
        callbackOnInit: null,
        callbackOnCreateTemplates: function (template) {
          return {
            item: ({ classNames }, data) => {
              const filterAttrs = Object.entries(data.customProperties).reduce(
                (acc, [key, value]) => {
                  return `${acc} ${key}=".${value}"`;
                },
                ''
              );

              return template(
                `<div 
                class="${classNames.item} ${data.highlighted ? classNames.highlightedState : classNames.itemSelectable}" 
                data-item
                data-id="${data.id}" 
                data-value="${data.value}" 
                ${data.active ? 'aria-selected="true"' : ''} ${data.disabled ? 'aria-disabled="true"' : ''} 
                ${filterAttrs}
                >${data.label}</div>`
              );
            },
            choice: ({ classNames }, data) => {
              const filterAttrs = Object.entries(data.customProperties).reduce(
                (acc, [key, value]) => {
                  return `${acc} ${key}=".${value}"`;
                },
                ''
              );

              return template(`<div 
                class="${classNames.item} ${classNames.itemChoice} ${data.disabled ? classNames.itemDisabled : classNames.itemSelectable}" 
                data-select-text="${this.config.itemSelectText}" 
                data-choice
                data-id="${data.id}" 
                data-value="${data.value}" 
                ${data.groupId > 0 ? 'role="treeitem"' : 'role="option"'} 
                ${filterAttrs}
                >
                 ${data.label}
               </div>`);
            },
            option(props) {
              // add initial data-attributes to choices <option>s
              const opt = new Option(
                props.label,
                props.value,
                false,
                props.active
              );

              if (props.customProperties) {
                Object.entries(props.customProperties).forEach(
                  ([key, value]) => {
                    if (key !== '') {
                      opt.dataset[key] = value;
                    }
                  }
                );
              }
              opt.disabled = !!props.disabled;

              return opt;
            },
          };
        },
      });

      // Get <option>s data attributes
      options.forEach((option, index) => {
        const choice = choices._store.getChoiceById(index + 1);

        if (choice) {
          Object.entries(option.dataset).forEach(([key, value]) => {
            choice.customProperties[key] = value;
          });
        }
      });

      choices._renderChoices();
    });

    var container = document.querySelector('.catalog__products');

    Isotope.Item.prototype._create = function () {
      // assign id, used for original-order sorting
      this.id = this.layout.itemGUID++;
      // transition objects
      this._transn = {
        ingProperties: {},
        clean: {},
        onEnd: {},
      };
      this.sortData = {};
    };

    Isotope.Item.prototype.layoutPosition = function () {
      this.emitEvent('layout', [this]);
    };

    // layout mode that does not position items
    Isotope.LayoutMode.create('none');

    var iso = new Isotope(container, {
      itemSelector: '.catalog__product',
      layoutMode: 'none',
      transformsEnabled: false,
      transitionDuration: 0,
    });

    iso.on('arrangeComplete', () => {
      container?.classList.remove('hidden');
      container?.classList.add('arranged');
    });

    const filters = {};

    selects.forEach((select) => {
      select.addEventListener('change', (event) => {
        const filterGroup = select.getAttribute('data-filter-group');
        const selectedOption = select.options[select.selectedIndex];
        filters[filterGroup] = selectedOption.dataset.filter;
        const filterValue = Object.values(filters).join('');

        arrange(filterValue);
      });
    });

    const arrange = (filterValue) => {
      container?.classList.remove('arranged');
      container?.classList.add('hidden');
      setTimeout(() => {
        iso.arrange({ filter: filterValue });
      }, 300);
    };
  }
};
